<template>
	<div class="videoPlay" :class="[{openLeft:isOpenLeft},{openRight:isOpenRight}]">
		<div class="L_course">
			<div class="L_course_header" v-if="isOpenLeft">
				<div class="goBack" @click="goBack">
					<i class="el-icon-arrow-left back_icon"></i>
					<span class="back_text">返回课程列表</span>
				</div>
				<div class="course_title">课程目录</div>
			</div>
			<el-scrollbar class="course_menu" v-if="isOpenLeft">
				<div class="course_item" v-for="(item,index) in mlList" :key="index" :class="{active:cuur_Courseware_id == item.cow_id}" @click="changeVideo(item,index)">
					<i class="el-icon-caret-right"></i>
					<span class="course_name" :title="item.cow_name">{{item.cow_name}}</span>
					<i class="el-icon-success" v-if="item.isStudy == 2"></i>
					<i class="el-icon-arrow-down" v-else-if="item.isStudy == 1"></i>
					<span class="noStart" v-else-if="item.isStudy == 0"></span>
				</div>
			</el-scrollbar>
			<div class="Left_icon" :class="{openLeft:isOpenLeft}" @click="leftOpenHide">
				<i class="el-icon-arrow-left" v-if="isOpenLeft"></i>
				<i class="el-icon-arrow-right" v-else></i>
			</div>
		</div>
		<div class="player" id="mse">
			<!-- <div id="mse"></div> -->
		</div>
		<div class="R_con">
			<el-scrollbar class="R_scrollbar" v-if="isOpenRight">
				<div class="takeNotes">写笔记</div>
				<div id="wangeditor">
					
				</div>
				<div class="preserve">
					<span @click="addNotes">保存</span>
				</div>
				<div class="takeNotes">我的笔记</div>
				<div v-if="total" class="notes_box">
					<div class="notesList">
						<div class="notesItem" v-for="(item,index) in notesList" :key="index">
							<div class="notesCon" v-html="html_decode(item.cono_notes)"></div>
							<div class="notesBottom">
								<span @click="showEditNotes(item)"><i class="el-icon-edit"></i>编辑</span>
								<el-popconfirm
									title="确定要删除该笔记吗？"
									@confirm="deleteNotes(item)"
								>
									<span slot="reference"><i class="el-icon-delete"></i>删除</span>
								</el-popconfirm>
								
								<span>{{item.createtime.substring(0,10)}}</span>
							</div>
						</div>
					</div>
					<el-pagination
					  background
					  layout="prev, pager, next"
					  :total="total"
					  :page-size="pageSize"
					  @current-change="changPage"
					  v-if="total > pageSize">
					</el-pagination>
				</div>
				<div v-else class="noData">
					<img src="@/views/images/noData.png" />
					<p>暂无数据</p>
				</div>
			</el-scrollbar>
			<div class="Right_icon" :class="{openRight:isOpenRight}" @click="rightOpenHide">
				<i class="el-icon-arrow-right" v-if="isOpenRight"></i>
				<i class="el-icon-arrow-left" v-else></i>
			</div>
		</div>
		<el-dialog
		  title="编辑笔记"
		  :visible.sync="notesVisible"
		   width="900px" 
		   custom-class="notesDialog" 
		   :close-on-click-modal="false">
		  <div id="editWangeditor">
		  </div>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="notesVisible = false">取 消</el-button>
		    <el-button type="primary" @click="editNotes">确 定</el-button>
		  </span>
		</el-dialog>
		<el-dialog
		  :visible.sync="tryItVisible"
		  width="400px"
		  :close-on-click-modal="false"
		  :close-on-press-escape="false"
		  :show-close="false"
		  custom-class="tryItDialog">
		  <span>当前课程未报名，试看时间结束，请报名后观看</span>
		  <span slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="gotoBuy" size="mini">前往报名</el-button>
		  </span>
		</el-dialog>
	</div>
</template> 

<script>
	import Player from 'xgplayer';
	import E from 'wangeditor';
	import HlsJsPlayer from 'xgplayer-hls.js';
	export default {
		data() {
			return {
				isOpenLeft:true,//是否展开左侧
				isOpenRight:true,//是否展开右侧
				mlList:[],//课程目录
				editor:null,//编辑器
				notesList:[],//笔记列表
				cuur_Courseware_id:null,//当前课件
				total:0,
				pageSize:1,
				notesVisible:false,//修改弹窗
				cuurNotes:{},//当前笔记
				editEditor:null,//修改编辑器
				player:null,//视频播放
				interval:null,//计时器
				times:0,//计时器时间
				speedNum:1,//倍速
				configInfo:{},//系统配置
				isBuy:0,//是否购买
				tryItVisible:false,//试看结束提醒
			}
		},
		methods:{
			//获取课程目录
			getCourseware(cuur_Courseware_id){
				var that = this;
				this.$http.post(this.PublicJs.publicPath + "/api/student.Order/courseWareList.html", {
					course_id:this.$route.query.course_id,
					token:window.sessionStorage.getItem('token')
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 0){
						this.mlList = response.data.data;
						if(this.mlList.length == 0){
							this.$message({
								message: "该课程没有课件，即将返回",
								type: 'warning',
								showClose: true
							});
							setTimeout(function(){
								//返回
								that.goBack()
							},3000);
						}else{
							this.isBuy = response.data.isBuy;
							if(this.isBuy == 1){
								if(cuur_Courseware_id){
									this.cuur_Courseware_id = cuur_Courseware_id
								}else{
									if(response.data.bofang){
										this.cuur_Courseware_id = response.data.bofang.stti_courseware_id;
									}else{
										this.cuur_Courseware_id = this.mlList[0].cow_id
									}
								}
							}else{
								this.cuur_Courseware_id = this.mlList[0].cow_id
							}
							//获取笔记
							this.getNotesList(1);
							//获取当前播放视频信息
							this.videoInfo();
						}
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//获取笔记
			getNotesList(page){
				this.$http.post(this.PublicJs.publicPath + "/api/student.Notes/index.html", {
					token:window.sessionStorage.getItem('token'),
					course_id:this.$route.query.course_id,
					cono_coursewareid:this.PublicJs.Encrypt(this.cuur_Courseware_id),
					limit:this.pageSize,
					page:page
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.notesList = response.data.data.data;
						this.total = response.data.data.total;
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						this.$router.go(0)
					}
				});
			},
			//左侧展开收起
			leftOpenHide(){
				this.isOpenLeft = !this.isOpenLeft
			},
			//右侧展开收起
			rightOpenHide(){
				var that = this;
				this.isOpenRight = !this.isOpenRight
				if(this.isOpenRight){
					this.$nextTick(()=>{
						//初始富文本编辑器
						that.loadEditor()
					})
				}
			},
			//初始富文本编辑器
			loadEditor(){
				var that = this;
				that.editor = new E('#wangeditor')
				that.editor.config.height = 244;
				that.editor.config.zIndex = 0;
				that.editor.config.placeholder = '请输入笔记内容';
				that.editor.config.focus = false; //取消自动聚焦
				that.editor.config.menus = [
					'bold',
					'italic',
					'underline',
					'justify',
					'list',
					'indent',
					'image',
				]
				that.editor.create()
			},
			//初始修改富文本编辑器
			loadeditEditor(){
				var that = this;
				that.editEditor = new E('#editWangeditor')
				that.editEditor.config.height = 244;
				that.editEditor.config.zIndex = 0;
				that.editEditor.config.focus = false; //取消自动聚焦
				that.editEditor.config.menus = [
					'bold',
					'italic',
					'underline',
					'justify',
					'list',
					'indent',
					'image',
				]
				that.editEditor.create()
				that.editEditor.txt.html(that.html_decode(that.cuurNotes.cono_notes))
			},
			//返回课程列表
			goBack(){
				//清除计时器
				clearInterval(this.interval)
				this.interval = null;
				this.times = 0;
				var source = this.PublicJs.Decrypt(this.$route.query.source)
				// if(source == "courseList"){
				// 	this.$router.push({
				// 		path: "/courseList"
				// 	});
				// }else if(source == "courseInfo"){
				// 	this.$router.push({
				// 		path: "/courseInfo",
				// 		query: {
				// 			course_id:this.$route.query.course_id
				// 		}
				// 	});
				if(source == "specialList"){
					window.sessionStorage.setItem('isHome',false)
					window.sessionStorage.setItem('menuChose',this.PublicJs.Encrypt("special"))
					this.$router.push({
						path: "/specialList"
					});
				}else if(source == "courseList"){
					window.sessionStorage.setItem('isHome',false)
					window.sessionStorage.setItem('menuChose',this.PublicJs.Encrypt("course"))
					this.$router.push({
						path: "/courseList"
					});
				}else if(source == "specialInfo"){
					window.sessionStorage.setItem('isHome',false)
					window.sessionStorage.setItem('menuChose',this.PublicJs.Encrypt("special"))
					this.$router.push({
						path: "/specialInfo",
						query: {
							zt_id:this.$route.query.zt_id
						}
					});
				}else if(source == "course"){
					this.$router.push({
						path: "/course"
					});
				}else if(source == "courseInfo"){
					this.$router.push({
						path: "/courseInfo",
						query: {
							course_id:this.$route.query.course_id
						}
					});
				}
			},
			//添加笔记
			addNotes(){
				if(this.isBuy == 1){
					this.$http.post(this.PublicJs.publicPath + "/api/student.Notes/add.html", {
						cono_courseid:this.$route.query.course_id,
						token:window.sessionStorage.getItem('token'),
						cono_notes:this.editor.txt.html(),
						cono_coursewareid:this.PublicJs.Encrypt(this.cuur_Courseware_id),
					}, {
						emulateJSON: true
					}).then(response => {
						if(response.data.code == 1){
							this.$message({
								message: response.data.msg,
								type: 'success',
								showClose: true
							});
							this.editor.txt.clear();
							//获取笔记
							this.getNotesList(1)
						}else{
							this.$message({
								message: response.data.msg,
								type: 'warning',
								showClose: true
							});
						}
					}, response => {
						if(response.status == 401){
							var that = this;
							this.$message({
								message: "您的账号在别处登录，请重新登录",
								type: 'warning',
								showClose: true
							});
							window.sessionStorage.removeItem('user_id')
							window.sessionStorage.removeItem('token')
							window.sessionStorage.removeItem('userinfo')
							setTimeout(function(){
								that.$router.go(0)
							},2000); 
							
						}
					});
				}else{
					this.$message({
						message: "当前课程未报名，请报名后记录笔记",
						type: 'warning',
						showClose: true
					});
				}
			},
			//笔记分页
			changPage(val){
				//获取笔记
				this.getNotesList(val)
			},
			//反转义
			html_decode(str){
				var s = ""; 
				if (str.length == 0) return ""; 
				s = str.replace(/&amp;/g, "&"); 
				s = s.replace(/&lt;/g, "<"); 
				s = s.replace(/&gt;/g, ">"); 
				s = s.replace(/&nbsp;/g, " "); 
				s = s.replace(/&#39;/g, "\'"); 
				s = s.replace(/&quot;/g, "\""); 
				s = s.replace(/<br\/>/g, "\n"); 
				return s; 
			},
			//删除笔记
			deleteNotes(item){
				this.$http.post(this.PublicJs.publicPath + "/api/student.Notes/del.html", {
					token:window.sessionStorage.getItem('token'),
					cono_id:this.PublicJs.Encrypt(item.cono_id),
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.$message({
							message: response.data.msg,
							type: 'success',
							showClose: true
						});
						//获取笔记
						this.getNotesList(1)
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//展示修改弹窗
			showEditNotes(item){
				var that = this;
				this.notesVisible = true;//修改弹窗
				this.cuurNotes = item;//当前笔记
				this.$nextTick(()=>{
					//初始修改富文本编辑器
					that.loadeditEditor()
				})
			},
			//修改笔记
			editNotes(){
				this.$http.post(this.PublicJs.publicPath + "/api/student.Notes/edit.html", {
					cono_id:this.PublicJs.Encrypt(this.cuurNotes.cono_id),
					token:window.sessionStorage.getItem('token'),
					cono_notes:this.editEditor.txt.html(),
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.$message({
							message: response.data.msg,
							type: 'success',
							showClose: true
						});
						this.notesVisible = false;
						//获取笔记
						this.getNotesList(this.PublicJs.Encrypt(this.cuur_Courseware.cow_id),1)
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//获取当前播放视频信息
			videoInfo(){
				var that = this;
				if(that.player){
					that.player.destroy();
				}
				this.$http.post(this.PublicJs.publicPath + "/api/student.Order/video.html", {
					cow_id:this.PublicJs.Encrypt(this.cuur_Courseware_id),
					token:window.sessionStorage.getItem('token'),
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						var playerInfo = response.data.data
						var url = ""
						if(playerInfo.cdn_video){
							url = playerInfo.cdn_video
						}else if(playerInfo.local_video){
							url = playerInfo.local_video
						}
						var lastPlayTime = 0;
						if(this.isBuy == 1 && playerInfo.progress){
							lastPlayTime = playerInfo.progress;
						}
						var cdnType = playerInfo.cdn_video ? playerInfo.cdn_video.slice(playerInfo.cdn_video.lastIndexOf(".") + 1) : "";
						var localType = playerInfo.local_video ? playerInfo.local_video.slice(playerInfo.local_video.lastIndexOf(".") + 1) : "";
						this.$nextTick(()=>{
							if(url.slice(url.lastIndexOf(".") + 1)!="m3u8"){
								that.player = new Player({
									id: 'mse',
									url: url,
									playbackRate: [1, 1.5, 2],
									lastPlayTime:lastPlayTime,
									poster:playerInfo.cow_thumb,
									allowSeekPlayed: true
								})
							}else{
								that.player = new HlsJsPlayer({
									id: 'mse',
									url: url,
									lastPlayTime:lastPlayTime,
									poster:playerInfo.cow_thumb,
									allowSeekPlayed: true
								});
							}
							if(cdnType == localType){
								that.player.emit('resourceReady', [{name: '线路一', url: playerInfo.cdn_video}, {name: '线路二', url: playerInfo.local_video}]);
							}
							//捕获错误
							that.player.on('error', function (e) {
								console.log(e)
								if(e.networkState == "未找到音频/视频来源"){
									if(cdnType == localType){
										if(url == playerInfo.cdn_video){
											url = playerInfo.local_video;
											that.player.src = playerInfo.local_video;
										}else{
											url = playerInfo.cdn_video;
											that.player.src = playerInfo.cdn_video;
										}
									}else{
										that.$message({
											message: "未找到音频/视频来源，请联系客服",
											type: 'warning',
											showClose: true
										});
									}
								}
							})
							if(that.isBuy == 1){
								//暂停
								that.player.on('pause', function (e) {
									//清除计时器
									clearInterval(that.interval)
									that.interval = null
								})
								//开始
								that.player.on('play', function (e) {
									if(!that.interval){
										//记录学时
										that.interval = setInterval(that.recordlearning, 1000);
									}
									console.log("开始")
								})
								//播放速度发生变化
								that.player.on('playbackrateChange', function (e) {
									//记录倍速
									that.speedNum = e.to
								})
								//结束
								that.player.on('ended', function (e) {
									var index = that.mlList.findIndex(function(item) {
									    return item.cow_id === that.cuur_Courseware_id;
									});
									if(index < (that.mlList.length-1)){
										//获取课程目录
										that.getCourseware(that.mlList[index+1].cow_id)
									}
								})
								
							}else{
								//监听播放时间
								that.player.on('timeupdate', function () {
									if(Number(that.player.currentTime) >= that.configInfo.con_trysee){
										that.player.pause();
										that.tryItVisible = true;
									}
								})
							}
						})
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//记录学时
			recordlearning(){
				this.times ++;
				if (!this.interval) {
					return;
				}
				if(this.times >= this.configInfo.tiktok_time){
					this.$http.post(this.PublicJs.publicPath + "/api/student.time/add.html", {
						token:window.sessionStorage.getItem('token'),
						courseware_id:this.PublicJs.Encrypt(this.cuur_Courseware_id),
						progress:this.player.currentTime,
						times:this.speedNum
					}, {
						emulateJSON: true
					}).then(response => {
						
					}, response => {
						if(response.status == 401){
							var that = this;
							this.$message({
								message: "您的账号在别处登录，请重新登录",
								type: 'warning',
								showClose: true
							});
							window.sessionStorage.removeItem('user_id')
							window.sessionStorage.removeItem('token')
							window.sessionStorage.removeItem('userinfo')
							setTimeout(function(){
								that.$router.go(0)
							},2000); 
							
						}
					});
					this.times = 0;
				}
			},
			//点击切换视频
			changeVideo(item,index){
				if(this.isBuy == 1){
					//获取课程目录
					this.getCourseware(item.cow_id)
				}else{
					if(index == 0){
						//获取课程目录
						this.getCourseware(item.cow_id)
					}else{
						this.$message({
							message: "当前课程未报名，只能试看第一节课程，请报名后观看",
							type: 'warning',
							showClose: true
						});
					}
				}
			},
			//前往购买
			gotoBuy(){
				this.tryItVisible = false;
				this.$router.push({
					path: "/courseInfo",
					query: {
						course_id:this.$route.query.course_id
					}
				});
			},
		},
		created() {
			var that = this;
			if(this.$route.query.cuur_cow_id){
				//获取课程目录
				this.getCourseware(this.PublicJs.Decrypt(this.$route.query.cuur_cow_id));
			}else{
				//获取课程目录
				this.getCourseware();
			}
			//配置信息
			this.configInfo = JSON.parse(this.PublicJs.Decrypt(window.sessionStorage.getItem('configInfo')))
			this.$nextTick(()=>{
				//初始富文本编辑器
				that.loadEditor()
			})
		}
	}
</script>

<style scoped="scoped">
	.videoPlay{
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: relative;
		padding-left: 16px;
		padding-right: 16px;
	}
	.videoPlay.openLeft{
		padding-left: 390px;
	}
	.videoPlay.openRight{
		padding-right: 400px;
	}
	.L_course{
		width: 16px;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: #383B3F;
		padding-top: 100px;
		padding-right: 16px;
	}
	.videoPlay.openLeft .L_course{
		width: 390px;
	}
	.L_course_header{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100px;
		padding-left: 32px;
	}
	.goBack{
		font-size: 0;
		line-height: 76px;
		cursor: pointer;
	}
	.back_icon{
		display: inline-block;
		width: 24px;
		height: 24px;
		background-color: #FFF;
		border-radius: 12px;
		vertical-align: middle;
		color: #383B3F;
		font-size: 20px;
		text-align: center;
		line-height: 24px;
		font-weight: bold;
	}
	.back_text{
		font-size: 16px;
		color: #FFF;
		vertical-align: middle;
		margin-left: 8px;
	}
	.goBack:hover .back_text{
		color: #5e7bf2;
	}
	.goBack:hover .back_icon{
		background-color: #5e7bf2;
	}
	.course_title{
		font-size: 18px;
		color: #FFF;
		line-height: 18px;
	}
	.course_menu{
		width: 100%;
		height: 100%;
		padding-left: 32px;
		padding-top: 10px;
	}
	.course_item{
		line-height: 50px;
		font-size: 0;
		cursor: pointer;
		position: relative;
	}
	.course_item .el-icon-caret-right{
		width: 18px;
		height: 18px;
		background-color: #FFF;
		border-radius: 9px;
		color: #383B3F;
		text-align: center;
		line-height: 18px;
		margin-right: 8px;
		font-size: 16px;
		vertical-align: middle;
	}
	.course_name{
		font-size: 12px;
		color: #FFF;
		vertical-align: middle;
		width: 280px;
		overflow: hidden;
		text-overflow:ellipsis;
		white-space: nowrap;
		display: inline-block;
	}
	.course_item .el-icon-success{
		position: absolute;
		color: #5e7bf2;
		font-size: 16px;
		right: 10px;
		top: 50%;
		margin-top: -8px;
	}
	.course_item .el-icon-arrow-down{
		position: absolute;
		width: 16px;
		height: 16px;
		background-color: #5e7bf2;
		color: #383B3F;
		text-align: center;
		line-height: 16px;
		font-size: 12px;
		right: 10px;
		top: 50%;
		margin-top: -8px;
		border-radius: 8px;
		transform:rotate(45deg);
		-ms-transform:rotate(45deg); 	
		-moz-transform:rotate(45deg); 	
		-webkit-transform:rotate(45deg); 
		-o-transform:rotate(45deg); 	
	}
	.course_item.active .el-icon-caret-right{
		background-color:#5e7bf2;
	}
	.course_item.active .course_name{
		color:#5e7bf2;
	}
	.noStart{
		position: absolute;
		width: 16px;
		height: 16px;
		right: 10px;
		top: 50%;
		margin-top: -8px;
		border-radius: 8px;
		border: 3px solid #999999;
	}
	.course_item.active .noStart{
		border: 3px solid #5e7bf2;
	}
	.Left_icon{
		position: absolute;
		right: 0;
		top: 50%;
		width: 16px;
		height: 70px;
		text-align: center;
		line-height: 70px;
		border-radius: 10px 0 0 10px;
		background-color: #4C4F53;
		color: #FFF;
		cursor: pointer;
	}
	.Left_icon i{
		font-size: 18px;
	}
	.player{
		width: 100% !important;
		height: 100% !important;
		background-color: #000;
	}
	.R_con{
		width: 16px;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		background-color: #F9F9F9;
	}
	.videoPlay.openRight .R_con{
		width: 400px;
		padding: 20px 30px;
	}
	.R_scrollbar{
		width: 100%;
		height: 100%;
	}
	.takeNotes{
		font-size: 20px;
		color: #333;
		line-height: 50px;
	}
	.preserve{
		width: 100%;
		height: 30px;
		text-align: right;
		margin: 24px 0 ;
	}
	.preserve span{
		display: inline-block;
		width: 60px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		font-size: 14px;
		color: #FFF;
		background-color: #5e7bf2;
		border-radius: 15px;
		cursor: pointer;
	}
	.preserve span:hover{
		opacity: 0.8;
	}
	.Right_icon{
		position: absolute;
		left: 0;
		top: 50%;
		width: 16px;
		height: 70px;
		text-align: center;
		line-height: 70px;
		border-radius: 0 10px 10px 0;
		background-color: #E8E8E8;
		color: #FFF;
		cursor: pointer;
	}
	.Right_icon i{
		font-size: 18px;
	}
	.notes_box{
		width: 100%;
		height: 100%;
	}
	.notesItem{
		margin-bottom: 20px;
	}
	.notes_box .el-pagination{
		text-align: center;
		margin-top: 20px;
	}
	.notesCon{
		margin-bottom: 20px;
	}
	.notesBottom{
		text-align: right;
		font-size: 14px;
		color: #999;
	}
	.notesBottom span{
		margin-left: 20px;
		cursor: pointer;
	}
	.notesBottom span i{
		margin-right: 5px;
		font-size: 18px;
	}
	.noData{
		text-align: center;
	}
	.noData p{
		font-size: 14px;
		line-height: 60px;
	}
</style>
<style>
	.course_menu .el-scrollbar__wrap,
	.R_scrollbar .el-scrollbar__wrap{
		overflow-x: hidden;
	}
	.notesDialog .el-dialog__header {
	    background-color: #4D6EF2;
	    height: 50px;
	    line-height: 50px;
		padding: 0 20px;
	}
	.notesDialog .el-dialog__title{
		color: #fff;
		font-size: 16px;
	}
	.notesDialog .el-dialog__headerbtn {
	    top: 14px;
	    font-size: 20px;
	}
	.notesDialog .el-dialog__headerbtn .el-dialog__close {
	    color: #FFF;
	}
	.tryItDialog .el-dialog__header{
		padding: 0;
	}
	.tryItDialog .el-dialog__footer{
		padding-top: 0;
	}
</style>
